import { Link } from '@swe/shared/providers/router/link';
import { Trans } from '@swe/shared/tools/intl/components/trans';
import { Box } from '@swe/shared/ui-kit/components/box';
import Button from '@swe/shared/ui-kit/components/button';
import Form, { yup } from '@swe/shared/ui-kit/components/form';
import Hero from '@swe/shared/ui-kit/components/form-hero';
import { RowSpacer } from '@swe/shared/ui-kit/components/form/row-spacer';
import Grid from '@swe/shared/ui-kit/components/grid';

import { usePrompt } from '@swe/shared/ui-kit/components/promt';
import Stack from '@swe/shared/ui-kit/components/stack';

import { useCallback } from 'react';

import { OAuthButtons } from 'common/components/forms/oauth';
import AgreementDisclaimer from 'common/containers/agreement-disclaimer';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate, useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';

type SignInGuestFormFields = {
  email: string;
  password: string;
};

type SignInFormProps = {
  onSubmit: (data: SignInGuestFormFields) => void;
};

const VALIDATION_SCHEMA = yup.object({
  email: yup.string().email('Email is incorrect').required('Email is required'),
  password: yup.string().required('Password is required'),
});
const INITIAL_VALUES: SignInGuestFormFields = { email: '', password: '' };

const SignInAsGuestForm = ({ onSubmit }: SignInFormProps) => {
  const query = useRouterQuery();
  const navigate = useRouterNavigate();
  const { confirm } = usePrompt();
  const { toSignIn } = useCurrentUser();

  const handlerClickGuest = useCallback(async () => {
    const res = await confirm({
      heading: 'Continue as guest?',
      ariaLabel: 'Continue as guest?',
      content: (
        <Trans
          id="domains.auth.signIn.continueAsGuestDisclaimer"
          defaultMessage="By continuing as guest, please note that loyalty points and certain discounts may not be available"
        />
      ),
      confirmLabel: 'Continue',
    });
    if (!res) {
      return;
    }
    return navigate({
      pathname: Routes.Guest,
      query,
    });
  }, [confirm, navigate, query]);

  return (
    <div>
      <Hero title="Log In" />
      <Stack>
        <OAuthButtons
          extraButton={
            <Button
              block
              color="light"
              onClick={handlerClickGuest}
              name="continue-as-guest"
            >
              Continue as guest
            </Button>
          }
        />
        <Form.Builder
          initialValues={INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmit}
          name="sign-in"
        >
          <Stack spacing="none">
            <Form.Input
              label="Email"
              name="email"
              placeholder="Enter email"
              isClearable
              autocomplete="email"
            />
            <Form.Input
              label="Password"
              type="password"
              name="password"
              placeholder="Enter password"
              autocomplete="current-password"
            />
            <Grid.Row hAlign="center">
              <Grid.Cell cols="content">
                <Link href={Routes.PasswordRecovery}>Forgot your password?</Link>
                <RowSpacer />
              </Grid.Cell>
            </Grid.Row>
            <Grid.Row spacing="sm">
              <Grid.Cell cols={6}>
                <Button
                  block
                  color="light"
                  onClick={() => toSignIn(false, true)}
                  name="sign-up"
                >
                  Sign Up
                </Button>
              </Grid.Cell>
              <Grid.Cell cols={6}>
                <Form.SubmitButton block>Log In</Form.SubmitButton>
              </Grid.Cell>
            </Grid.Row>
            <Box mt="sm">
              <Grid.Row hAlign="center">
                <Grid.Cell cols="content">
                  <AgreementDisclaimer
                    type="signIn"
                    spacer={false}
                  />
                </Grid.Cell>
              </Grid.Row>
            </Box>
          </Stack>
        </Form.Builder>
      </Stack>
    </div>
  );
};

export type { SignInGuestFormFields };
export { SignInAsGuestForm };

import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { SignIn } from 'domains/authorization/containers/sign-in';

const SignInPage: ApplicationPage = () => {
  return (
    <Page>
      <SignIn />
    </Page>
  );
};

SignInPage.getMeta = () => ({ title: 'Sign In' });

SignInPage.getMainLayoutProps = () => ({
  size: 'sm',
  centered: true,
  subheader: null,
});

export default SignInPage;

import Stack from '@swe/shared/ui-kit/components/stack';

import { useAccountFulfillmentPreferences } from 'common/containers/header/containers/sub-header/use-cases/use-account-fulfillment-preferences';

import { useCartMeta } from 'common/providers/cart/context';
import { useStoreConfig } from 'common/providers/config';
import { useRouterQuery } from 'common/router';

import { useSaleType } from 'common/use-cases/use-sale-type';
import { SignInForm } from 'domains/authorization/components/forms/sign-in';
import { SignInAsGuestForm } from 'domains/authorization/components/forms/sign-in-as-guest';
import { ReferralProgramAlert } from 'domains/authorization/containers/referral-program-alert';
import { ProductSaleType } from 'entities/product/product';
import { ShopFulfillmentType } from 'entities/shop/info';

const SignIn = () => {
  const { guestSettings } = useStoreConfig();
  const { saleType } = useSaleType();
  const { currentUser, orderType } = useAccountFulfillmentPreferences();
  const query = useRouterQuery();
  const { hasMedicalProducts } = useCartMeta();

  const isAvailableGuest =
    !!query.r &&
    query.r.includes('/cart') &&
    query.to === 'checkout' &&
    guestSettings?.isGuestEnabled &&
    (((ProductSaleType.RECREATIONAL === saleType || !saleType) &&
      orderType === ShopFulfillmentType.Pickup &&
      !hasMedicalProducts) ||
      guestSettings?.isMedicalGuest);

  return (
    <Stack spacing="lg">
      <ReferralProgramAlert />
      {isAvailableGuest ? (
        <SignInAsGuestForm onSubmit={currentUser.login} />
      ) : (
        <SignInForm onSubmit={currentUser.login} />
      )}
    </Stack>
  );
};

export { SignIn };
export default SignIn;

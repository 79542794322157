import { Link } from '@swe/shared/providers/router/link';
import Button from '@swe/shared/ui-kit/components/button';
import Form, { yup } from '@swe/shared/ui-kit/components/form';
import Hero from '@swe/shared/ui-kit/components/form-hero';
import { RowSpacer } from '@swe/shared/ui-kit/components/form/row-spacer';
import Grid from '@swe/shared/ui-kit/components/grid';

import Stack from '@swe/shared/ui-kit/components/stack';

import { OAuthButtons } from 'common/components/forms/oauth';
import AgreementDisclaimer from 'common/containers/agreement-disclaimer';
import { useCurrentUser } from 'common/providers/user';
import { Routes } from 'common/router/constants';

type SignInFormFields = {
  email: string;
  password: string;
};

type SignInFormProps = {
  onSubmit: (data: SignInFormFields) => void;
};

const VALIDATION_SCHEMA = yup.object({
  email: yup.string().email('Email is incorrect').required('Email is required'),
  password: yup.string().required('Password is required'),
});
const INITIAL_VALUES: SignInFormFields = { email: '', password: '' };

const SignInForm = ({ onSubmit }: SignInFormProps) => {
  const { toSignIn } = useCurrentUser();

  return (
    <div>
      <Hero title="Log In" />
      <Stack>
        <OAuthButtons />
        <Form.Builder
          initialValues={INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmit}
          name="sign-in"
        >
          <Stack spacing="none">
            <Form.Input
              label="Email"
              name="email"
              placeholder="Enter email"
              isClearable
              autocomplete="email"
            />
            <Form.Input
              label="Password"
              type="password"
              name="password"
              placeholder="Enter password"
              autocomplete="current-password"
            />
            <div>
              <AgreementDisclaimer type="signIn" />
            </div>
            <Grid.Row spacing="sm">
              <Grid.Cell cols={6}>
                <Button
                  block
                  color="light"
                  onClick={() => toSignIn(false, true)}
                  name="sign-up"
                >
                  Sign Up
                </Button>
              </Grid.Cell>
              <Grid.Cell cols={6}>
                <Form.SubmitButton block>Log In</Form.SubmitButton>
              </Grid.Cell>
            </Grid.Row>
            <Grid.Row hAlign="center">
              <Grid.Cell cols="content">
                <RowSpacer />
                <Link href={Routes.PasswordRecovery}>Forgot your password?</Link>
              </Grid.Cell>
            </Grid.Row>
          </Stack>
        </Form.Builder>
      </Stack>
    </div>
  );
};

export type { SignInFormFields };
export { SignInForm };
export default SignInForm;
